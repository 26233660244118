import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';
export const macoAPI = {
  getMacos: params => getNoCatch(`${topic.macoTopic}/getMacoList`, params),
  addMaco: params => postNoCatch(`${topic.macoTopic}/addMaco`, params),
  editMaco: params => postNoCatch(`${topic.macoTopic}/editMaco`, params),
  getMacoById: params => getNoCatch(`${topic.macoTopic}/getMacoById`, params),
  deleteMacoByIds: params => getNoCatch(`${topic.macoTopic}/deleteMacoByIds`, params),
  getRequByType2List: params => getNoCatch(`${topic.macoTopic}/getRequByType2List`, params),
  addMtrbForMacoPart: params => getNoCatch(`${topic.macoTopic}/addMtrbForMacoPart`, params)
};
