import store from '@/store';
import { keep2Decimal, keepNumber } from '@assets/js/regExUtil';
import { getDateNoTime } from '@assets/js/dateUtils';
import Vue from 'vue';

const formatterUrgency = item => {
  switch (item) {
    case 0:
      return '一般';
    case 1:
      return '紧急';
    case 2:
      return '非常紧急';
  }
};
const { dept_id, stff_id } = Vue.prototype.$cookies.get('userInfo');

let businessDeptArray = [];
let purchaseDeptArray = [];
let accessoriesDeptArray = [];
let formStatus = '';
export const changeBusinessDept = array => (businessDeptArray = array);
export const changePurchaseDept = array => (purchaseDeptArray = array);
export const changeAccessoriesDept = array => (accessoriesDeptArray = array);
export const changeFormStatus = status => (formStatus = status);

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '材料咨询单编号', prop: 'maco_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '紧急度',
    prop: 'urgency',
    itemType: 'select',
    options: [
      { value: 0, label: '一般' },
      { value: 1, label: '紧急' },
      { value: 2, label: '非常紧急' }
    ],
    formatter: val => formatterUrgency(val),
    input: true,
    sortable: false,
    widthAuto: true
  },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '系列名称', prop: 'series_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户订单号', prop: 'cust_order_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '业务部门', prop: 'business_dept_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '要求报价日期',
    prop: 'maco_offer_date',
    itemType: 'date',
    input: true,
    sortable: true,
    widthAuto: true,
    valueFormat: 'timestamp',
    format: 'yyyy-MM-dd',
    formatter: val => getDateNoTime(val, false)
  },
  { label: '录入人', prop: 'stff_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '录入时间',
    prop: 'create_time',
    itemType: 'date',
    input: true,
    sortable: true,
    widthAuto: true,
    valueFormat: 'timestamp',
    format: 'yyyy-MM-dd',
    formatter: val => getDateNoTime(val, false)
  },
  { label: '备注', prop: 'remark', itemType: 'input', input: false, sortable: false, widthAuto: true, overflowTooltip: true },
  {
    label: '台面报价状态',
    prop: 'mesa_status',
    itemType: 'select',
    options: [
      { value: 0, label: '未报价' },
      { value: 1, label: '报价中' },
      { value: 2, label: '已报价' }
    ],
    input: true,
    sortable: false,
    widthAuto: true
  },
  {
    label: '单据报价状态',
    prop: 'order_status',
    itemType: 'select',
    options: [
      { value: 0, label: '未报价' },
      { value: 1, label: '报价中' },
      { value: 2, label: '已报价' }
    ],
    input: true,
    sortable: false,
    widthAuto: true
  },
  store.state.status
];
export const macoMainFormProperties = [
  {
    label: '客户简称',
    prop: 'cust_id',
    itemType: 'select',
    options: [],
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    filterable: true,
    disabled: false
  },
  {
    label: '系列名称',
    prop: 'series_name',
    itemType: 'input',
    maxlength: 50,
    model: '',
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '客户订单号',
    prop: 'cust_order_no',
    itemType: 'input',
    maxlength: 20,
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '紧急度',
    prop: 'urgency',
    itemType: 'select',
    model: '',
    options: [
      { label: '一般', value: 0 },
      { label: '紧急', value: 1 },
      { label: '非常紧急', value: 2 }
    ],
    size: 'small',
    rules: { required: false, message: ' ' },
    subWidth: 80,
    disabled: true,
    show: true
  },
  {
    label: '业务部门',
    prop: 'business_dept_id',
    model: '',
    itemType: 'select',
    options: [],
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '采购经办人',
    prop: 'podr_stff_id',
    itemType: 'select',
    options: [],
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '要求报价日期',
    prop: 'maco_offer_date',
    itemType: 'date',
    model: '',
    size: 'small',
    formatTime: 'yyyy-MM-dd',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    disabled: false,
    show: true
  },
  {
    label: '备注',
    prop: 'remark',
    itemType: 'input',
    model: '',
    type: 'textarea',
    size: 'small',
    rules: { required: false, message: ' ' },
    rows: 3,
    split: 24,
    subWidth: 80,
    disabled: false,
    show: true
  }
];
export const macoAddSubTableProperties = [
  {
    label: '成品客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      type: 'input',
      maxLength: 20,
      required: true,
      disabled: row => row.status > 0,
      copyAndPaste: true
    }
  },
  {
    label: '材料名称',
    prop: 'mtrb_name',
    itemType: 'input',
    maxLength: 20,
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      type: 'input',
      maxLength: 20,
      required: true,
      copyAndPaste: true,
      disabled: row => row.status > 0
    }
  },
  {
    label: '包材品类',
    prop: 'mtrb_pctype',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      type: 'select',
      options: [],
      required: true,
      copyAndPaste: true,
      disabled: row => row.status > 0
    }
  },
  {
    label: '产品类型',
    prop: 'mtrb_type',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      type: 'newSelect',
      options: {
        0: { value: 0, label: '新款' },
        1: { value: 1, label: '老款' },
        2: { value: 2, label: '修改款' }
      },
      required: true,
      copyAndPaste: true,
      disabled: row => row.status > 0
    }
  },
  {
    label: '材料规格',
    prop: 'mtrb_spec',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      type: 'popoverInput',
      maxLength: 255,
      required: false,
      copyAndPaste: true,
      disabled: row => row.status > 0
    }
  },
  {
    label: '委托打样单号',
    prop: 'requ_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: true, type: 'input' }
  },
  {
    label: '销售经办人',
    prop: 'scon_stff_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: true, type: 'input' }
  },
  {
    label: '包装要求',
    prop: 'prod_content',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: row => row.status > 0,
      type: 'popoverInput'
    }
  },
  {
    label: '订单数量',
    prop: 'order_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: row => row.status > 0,
      type: 'input',
      maxlength: 9,
      input: (val, row) => (row.order_num = keepNumber(val))
    }
  },
  {
    label: '单位',
    prop: 'maco_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: true, disabled: row => row.status > 0, type: 'select', options: [] }
  },
  {
    label: '到货日期',
    prop: 'arrival_date',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    formatter: val => getDateNoTime(val, false),
    subItem: {
      required: true,
      disabled: row => row.status > 0,
      type: 'date'
    }
  },
  {
    label: '材料收货工厂',
    prop: 'mtrb_abbr',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: true, disabled: row => row.status > 0, type: 'newSelect', options: [], filterable: true }
  },
  {
    label: '是否需要产前样',
    prop: 'is_prod_sample',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: true,
      disabled: row => row.status > 0,
      type: 'newSelect',
      options: {
        0: { value: 0, label: '否' },
        1: { value: 1, label: '是' }
      }
    }
  },
  {
    label: '备注',
    prop: 'remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: row => row.status > 0,
      type: 'popoverInput'
    }
  }
];
export const macoEditSubTableProperties = [
  {
    label: '成品客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      type: 'input',
      maxLength: 20,
      required: true,
      copyAndPaste: true,
      disabled: row => row.status > 0
    }
  },
  {
    label: '材料名称',
    prop: 'mtrb_name',
    itemType: 'input',
    maxLength: 20,
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      type: 'input',
      maxLength: 20,
      required: true,
      copyAndPaste: true,
      disabled: row => row.status > 0
    }
  },
  {
    label: '包材品类',
    prop: 'mtrb_pctype',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      type: 'select',
      options: [],
      required: true,
      copyAndPaste: true,
      disabled: row => row.status > 0
    }
  },
  {
    label: '产品类型',
    prop: 'mtrb_type',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      type: 'newSelect',
      options: {
        0: { value: 0, label: '新款' },
        1: { value: 1, label: '老款' },
        2: { value: 2, label: '修改款' }
      },
      required: true,
      copyAndPaste: true,
      disabled: row => row.status > 0
    }
  },
  {
    label: '材料规格',
    prop: 'mtrb_spec',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      type: 'popoverInput',
      maxLength: 255,
      required: false,
      copyAndPaste: true,
      disabled: row => row.status > 0
    }
  },
  {
    label: '委托打样单号',
    prop: 'requ_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: true, type: 'input' }
  },
  {
    label: '销售经办人',
    prop: 'scon_stff_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: false, disabled: true, type: 'input' }
  },
  {
    label: '包装要求',
    prop: 'prod_content',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: row => row.status > 0,
      type: 'popoverInput'
    }
  },
  {
    label: '订单数量',
    prop: 'order_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: row => row.status > 0,
      type: 'input',
      maxlength: 9,
      input: (val, row) => (row.order_num = keepNumber(val))
    }
  },
  {
    label: '单位',
    prop: 'maco_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: true, disabled: row => row.status > 0, type: 'select', options: [] }
  },
  {
    label: '到货日期',
    prop: 'arrival_date',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    formatter: val => getDateNoTime(val, false),
    subItem: {
      required: true,
      disabled: row => row.status > 0,
      type: 'date'
    }
  },
  {
    label: '材料收货工厂',
    prop: 'mtrb_abbr',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: { required: true, disabled: row => row.status > 0, type: 'newSelect', options: [], filterable: true }
  },
  {
    label: '外销备注',
    prop: 'remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: row => row.status > 0,
      type: 'popoverInput'
    }
  },
  {
    label: '是否需要产前样',
    prop: 'is_prod_sample',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: true,
      disabled: row => row.status > 0,
      type: 'newSelect',
      options: {
        0: { value: 0, label: '否' },
        1: { value: 1, label: '是' }
      }
    }
  },
  {
    label: '材料供应商编码',
    prop: 'supp_no_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: true,
      disabled: () => (formStatus > 0 ? !accessoriesDeptArray.includes(Number(dept_id)) : true),
      type: 'newSelect',
      options: [],
      filterable: true
    }
  },
  {
    label: '材料供应商名称',
    prop: 'supp_name_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: true,
      disabled: () => (formStatus > 0 ? !accessoriesDeptArray.includes(Number(dept_id)) : true),
      type: 'newSelect',
      options: [],
      filterable: true
    }
  },
  {
    label: '对应台面',
    prop: 'stff_id',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: true,
      disabled: true,
      type: 'newSelect',
      options: []
    }
  },
  {
    label: '材料报价',
    prop: 'mtrb_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    currColumnVisible: () => !purchaseDeptArray.includes(Number(dept_id)),
    subItem: {
      required: true,
      disabled: row => (formStatus > 0 ? !(accessoriesDeptArray.includes(Number(dept_id)) && row.stff_id === Number(stff_id)) : true),
      type: 'input',
      maxlength: 7,
      input: (val, row) => (row.mtrb_price = keep2Decimal(val))
    }
  },
  {
    label: '台面沟通交期',
    prop: 'table_top_delivery_time',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    formatter: val => getDateNoTime(val, false),
    subItem: {
      required: true,
      disabled: () => (formStatus > 0 ? !accessoriesDeptArray.includes(Number(dept_id)) : true),
      type: 'date'
    }
  },
  {
    label: '采购备注',
    prop: 'podr_remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: () => (formStatus > 0 ? !accessoriesDeptArray.includes(Number(dept_id)) : true),
      type: 'popoverInput'
    }
  },
  {
    label: '操作详情',
    prop: 'operation',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    currColumnVisible: () => businessDeptArray.includes(Number(dept_id))
  }
];
export const ESFTableProperties = [
  store.state.index,
  { label: '委托打样单号', prop: 'requ_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '委托人', prop: 'requ_stff_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '产品名称', prop: 'prod_name', itemType: 'input', input: true, sortable: false, widthAuto: true, overflowTooltip: true },
  { label: '我司货号', prop: 'prod_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '产品规格', prop: 'prod_spec', itemType: 'input', input: false, sortable: false, widthAuto: true, overflowTooltip: true }
];
