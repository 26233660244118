<template>
  <div class="vg_wrapper">
    <EditHeader
      :isShow="isShow"
      :btn="btn"
      :special="true"
      :strForm="{ status: formStatus }"
      @openEdit="openEdit"
      @closeEdit="closeEdit"
      @submit="submit"
      @refresh="initData"
    >
      <div slot="functionButton" class="vg_ml_8">
        <el-button v-if="formStatus === 1" :disabled="!isShow" class="vg_mb_8" size="small" type="danger" @click="() => {}"> 一键下发</el-button>
      </div>
      <div slot="otherButton">
        <div class="flexHV vg_mr_8">
          <span>材料询价单编号：</span>
          <el-input disabled size="small" :value="maco_no" style="width: 160px"></el-input>
        </div>
      </div>
      <div slot="specialStatus" style="display: flex" :set="((order = getStatusName(orderStatus)), (mesa = getStatusName(mesaStatus)))">
        <div class="flexHV vg_mr_8" v-if="formStatus === 0">
          <span>报价状态：</span>
          <el-tag :type="order.type">{{ order.name }}</el-tag>
        </div>
        <div class="flexHV vg_mr_8" v-if="formStatus === 1">
          <span>台面报价状态：</span>
          <el-tag :type="mesa.type">{{ mesa.name }}</el-tag>
        </div>
        <div class="flexHV">
          <span>状态：</span>
          <el-tag :type="helper.getStatusName(formStatus).type">{{ helper.getStatusName(formStatus).name }}</el-tag>
        </div>
      </div>
    </EditHeader>
    <DynamicForm
      v-bind="$attrs"
      :total-width="mainForm.totalWidth"
      :split="mainForm.split"
      :form-list="mainForm.formProperties"
      ref="dynamicForm"
      class="dynamicForm"
      :is-show="isShow"
      :computed-data="{ urgency: urgency }"
    />
    <div>
      <el-button v-if="formStatus === 0" :disabled="isShow" class="vg_mb_8" type="primary" size="small" @click="ESFDialogVisible = true">
        导入委托打样单</el-button
      >
      <el-button v-if="formStatus === 0" :disabled="isShow" class="vg_mb_8" type="primary" size="small" @click="addRow()">新增</el-button>
      <el-button v-if="formStatus === 1" :disabled="isShow" class="vg_mb_8" type="primary" size="small" @click="copyRow()">复制</el-button>
      <el-button :disabled="isShow" class="vg_mb_8" type="danger" size="small" @click="delRow()">删除</el-button>
    </div>
    <DynamicUTable
      ref="dynamicUTable"
      :tableData="subTableData"
      :columns="macoEditSubTableProperties"
      :need-pagination="false"
      :is-show="isShow"
      @selection-change="val => (subSelections = val)"
    >
      <template v-slot:default-operation="scope">
        <el-button v-if="formStatus === 2" :disabled="!isShow" type="text" @click="generateMaterialInfo(scope.row.maco_part_id)">生成材料信息</el-button>
      </template>
    </DynamicUTable>
    <el-dialog title="导入委托打样单" :visible.sync="ESFDialogVisible" width="70%" @opened="getESFTableData">
      <div class="vg_mb_8">
        <el-button type="info" size="small" icon="el-icon-refresh-right" @click="ESFDialogRefresh()"> 刷新</el-button>
      </div>
      <search-table
        ref="ESFTable"
        :data="ESFTableData"
        :tableRowKey="'ESFDialogInfo'"
        :columns="ESFTableProperties"
        v-loading="ESFLoadFlag"
        :total-page="ESFTotalPage"
        @row-dblclick="importESFConfirm"
        @getTableData="getESFTableData"
      >
      </search-table>
    </el-dialog>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditHeader from '@/views/component/editHeaderLine.vue';
import inputUser from '@/views/component/inputUser';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep } from 'lodash';
import {
  changeAccessoriesDept,
  changeBusinessDept,
  changeFormStatus,
  changePurchaseDept,
  ESFTableProperties,
  macoEditSubTableProperties,
  macoMainFormProperties
} from '@/views/SalesManagement/MacoManage/maco';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { macoAPI } from '@api/modules/maco';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { deleteTableRow } from '@assets/js/arrayUtils';
import { getCustomerInfo, getDept, getStff, getSupp, getUnit } from '@api/public';
import { getNoCatch } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import SearchTable from '@/components/table/SearchTableV2.vue';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'macoEditMain',
  components: {
    SearchTable,
    DynamicUTable,
    DynamicForm,
    EditHeader,
    inputUser
  },
  data() {
    return {
      isShow: true,
      btn: {},
      stffForm: {},
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(macoMainFormProperties)
      },
      subTableData: [],
      subSelections: [],
      subTableMap: new Map(),
      macoEditSubTableProperties: cloneDeep(macoEditSubTableProperties),
      formStatus: 0,
      orderStatus: 0,
      mesaStatus: 0,
      maco_no: '',
      maco_id: '',
      ESFDialogVisible: false,
      ESFTableData: [],
      ESFTableProperties: cloneDeep(ESFTableProperties),
      ESFLoadFlag: false,
      ESFTotalPage: 0,
      create_time: 0
    };
  },
  computed: {
    urgency() {
      let urgency = this.mainForm.formProperties.find(({ prop }) => prop === 'urgency');
      let maxDate = Math.max(...this.subTableData.map(({ arrival_date }) => arrival_date));
      let maxDateNoTime = new Date(maxDate);
      maxDateNoTime.setHours(0);
      maxDateNoTime.setMinutes(0);
      maxDateNoTime.setSeconds(0);
      let createTimeNoTime = new Date(this.create_time);
      createTimeNoTime.setHours(0);
      createTimeNoTime.setMinutes(0);
      createTimeNoTime.setSeconds(0);
      let dateTimeStamp = Number(((maxDateNoTime.getTime() - createTimeNoTime.getTime()) / 1000 / 24 / 60 / 60).toFixed(0));
      if (dateTimeStamp >= 20) {
        urgency.model = 0;
        return 0;
      } else if (dateTimeStamp > 7 && dateTimeStamp < 20) {
        urgency.model = 1;
        return 1;
      } else if (dateTimeStamp <= 7) {
        urgency.model = 2;
        return 2;
      } else {
        return '';
      }
    }
  },
  async created() {
    let yewu = await getDept('业务', 'ids');
    let neimao = await getDept('内贸', 'ids');
    let dianshang = await getDept('电商', 'ids');
    changeBusinessDept([...yewu, ...neimao, ...dianshang]);
    changePurchaseDept(await getDept('采购', 'ids'));
    changeAccessoriesDept(await getDept('辅料', 'ids'));
  },
  async mounted() {
    await this.initData();
    await this.changeProperties();
  },
  methods: {
    async initData() {
      this.getMacoData();
    },
    async changeProperties() {
      this.mainForm.formProperties.find(({ prop }) => prop === 'cust_id').options = await getCustomerInfo({ status: 2, type: 'abbr' });

      let bDept = this.mainForm.formProperties.find(({ prop }) => prop === 'business_dept_id');
      let yewu = await getDept('业务');
      let neimao = await getDept('内贸');
      let dianshang = await getDept('电商');
      bDept.options = [...yewu, ...neimao, ...dianshang];

      let [stffListObj, stffList] = await getStff('辅料', 'valueListAndDefault');
      this.mainForm.formProperties.find(({ prop }) => prop === 'podr_stff_id').options = stffList;
      let mtrb_pctype = this.macoEditSubTableProperties.find(({ prop }) => prop === 'mtrb_pctype');
      mtrb_pctype.subItem.change = (val, row) => {
        if (val === '布标' || val === '织标') {
          row.arrival_date = Number(this.create_time) + 864000000;
        } else {
          row.arrival_date = Number(this.create_time) + 1728000000;
        }
      };
      getNoCatch(optnAPI.getOptnByPermIdV1, { perm_id: 10024 }).then(({ data }) => {
        mtrb_pctype.subItem.options = Array.from(data.form.optn_cntt_list, ({ param1 }) => {
          return { value: param1, label: param1 };
        });
      });

      this.macoEditSubTableProperties.find(({ prop }) => prop === 'maco_unit').subItem.options = await getUnit('select', 'param1');

      let suppList = await getSupp('valueList', ['purc_stff_id']);
      let suppNoList = await getSupp('valueList', ['purc_stff_id'], 'supp_no');
      this.macoEditSubTableProperties.find(({ prop }) => prop === 'mtrb_abbr').subItem.options = {
        0: { value: 0, label: '待告' },
        ...suppList
      };
      let supp_no_id = this.macoEditSubTableProperties.find(({ prop }) => prop === 'supp_no_id');
      supp_no_id.subItem.options = suppNoList;
      supp_no_id.subItem.change = (val, row) => {
        row.supp_name_id = val;
        row.stff_id = suppList[val]?.purc_stff_id ?? '';
      };

      let supp_name_id = this.macoEditSubTableProperties.find(({ prop }) => prop === 'supp_name_id');
      supp_name_id.subItem.options = suppList;
      supp_name_id.subItem.change = (val, row) => {
        row.supp_no_id = val;
        row.stff_id = suppList[val]?.purc_stff_id ?? '';
      };
      this.macoEditSubTableProperties.find(({ prop }) => prop === 'stff_id').subItem.options = stffListObj;
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    getMacoData() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      macoAPI.getMacoById({ maco_id: props.form_id }).then(({ data }) => {
        this.btn = data.btn;
        this.maco_no = data.form.maco_no;
        this.maco_id = data.form.maco_id;
        this.formStatus = data.form.status;
        this.orderStatus = data.form.order_status;
        this.mesaStatus = data.form.mesa_status;
        this.create_time = data.form.create_time;
        this.changeStffForm(data.form);
        changeFormStatus(this.formStatus);
        this.mainForm.formProperties.forEach(item => (item.model = data.form[item.prop]));
        if (this.formStatus === 0)
          this.macoEditSubTableProperties.forEach((item, index) => {
            if (item.prop === 'business_dept_id') item.model = Number(this.$cookies.get('userInfo').dept_id);
            if (index > 13 && item.subItem) item.subItem.required = false;
          });
        this.subTableData = data.form.macoPartList;
      });
    },
    changeStffForm(form) {
      this.stffForm.stff_name = form.stff_name;
      this.stffForm.dept_name = form.dept_name;
      this.stffForm.dept_team_name = form.dept_team_name;
      this.stffForm.user_id = form.user_id;
      this.stffForm.dept_id = form.dept_id;
      this.stffForm.stff_id = form.stff_id;
    },
    submit() {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable = await this.$refs.dynamicUTable.fullValidateMsg();
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid && !dynamicUTable) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable) tempStr += `子表-${dynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    saveInfo() {
      macoAPI.editMaco(this.getSaveData()).then(({ data }) => {
        this.$message.success('保存成功!');
        this.initData();
        this.isShow = true;
        this.$emit('isShow', this.isShow);
      });
    },
    getSaveData() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.maco_id = this.maco_id;
      formModel.maco_no = this.maco_no;
      formModel.status = this.formStatus;
      formModel.mesa_status = this.mesaStatus;
      formModel.order_status = this.orderStatus;
      formModel.macoPartList = this.subTableData.concat(Array.from(this.subTableMap.values()));
      return formModel;
    },
    addRow() {
      let obj = cloneDeep(this.$refs.dynamicUTable.originalObject);
      obj.only_type = 1;
      this.subTableData.push(obj);
    },
    delRow() {
      if (this.subSelections.some(({ only_type }) => only_type === 1)) return this.$message.warning('包含唯一数据不可删除!');
      let { tableData, map } = deleteTableRow('maco_part_id', this.subTableData, this.subTableMap, this.subSelections);
      this.subTableData = tableData;
      this.subTableMap = map;
    },
    copyRow() {
      if (this.subSelections.length === 0) return this.$message.warning('请选择一条数据!');
      cloneDeep(this.subSelections).forEach(item => {
        item.maco_part_id = null;
        item.only_type = 0;
        this.subTableData.push(item);
      });
      this.$refs.dynamicUTable.reload(this.subTableData);
      this.$refs.dynamicUTable.clearSelection();
    },
    async getESFTableData() {
      this.ESFLoadFlag = true;
      await macoAPI.getRequByType2List(this.$refs.ESFTable.searchForm).then(({ data }) => {
        this.ESFTableData = data.list;
        this.ESFTotalPage = data.total;
        this.ESFLoadFlag = false;
      });
    },
    importESFConfirm(row) {
      let obj = cloneDeep(this.$refs.dynamicUTable.originalObject);
      obj.requ_no = row.requ_no;
      obj.scon_stff_name = row.requ_stff_name;
      obj.mtrb_name = row.prod_name;
      obj.mtrb_spec = row.prod_spec;
      this.subTableData.push(obj);
      this.ESFDialogVisible = false;
    },
    ESFDialogRefresh() {
      this.$refs.ESFTable.resetFields();
      this.getESFTableData();
    },
    getStatusName(status) {
      switch (status) {
        case 0:
          return { name: '未报价', type: 'info' };
        case 1:
          return { name: '报价中', type: 'warning' };
        case 2:
          return { name: '已报价', type: 'success' };
      }
    },
    generateMaterialInfo(id) {
      macoAPI.addMtrbForMacoPart({ maco_part_id: id }).then(({ data }) => {
        this.$message.success('生成成功!');
        this.jump('/mtrb_edit', {
          key: UrlEncode.encode(
            JSON.stringify({
              perm_id: 121,
              form_id: data.form_id
            })
          )
        });
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
